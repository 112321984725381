import React from 'react'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';

export const getBrowserLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage
    return userLanguage.split('-')[0];
};

export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/;
    return passwordRegex.test(password);
}

export const getNewPasswordValidationMessage = (password, t) => {
    const reqs = [
        { label: t('common:passworMinLength'), isValid: password.newPassword.length >= 8 },
        { label: t('common:mustHaveUpperCase'), isValid: /[A-Z]/.test(password.newPassword) },
        { label: t('common:mustHaveLowerCase'), isValid: /[a-z]/.test(password.newPassword) },
        { label: t('common:mustHaveDigit'), isValid: /\d/.test(password.newPassword) },
        { label: t('common:mustHaveSpecialCharacter'), isValid: /[^a-zA-Z\d]/.test(password.newPassword)},
        { label: t('common:passwordMustMatch'), isValid: (password.repeatPassword.length > 0 && password.repeatPassword === password.newPassword) }
    ]

    return (
        <>
            <p style={{ fontWeight: "bold" }}>{t('common:passwordRequirements') + ":"}</p>
            <span style={{ whiteSpace: 'pre-line' }}>
                {reqs.map((req, index) => (
                    <div key={index} style={{ color: req.isValid ? 'green' : 'red' }}>
                        {req.isValid ?
                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        :
                            <CancelOutlinedIcon style={{ color: 'red' }} />
                        }
                        {' ' + req.label + '.'}
                    </div>
                ))}
            </span>
        </>
    )
}
