import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'
import { IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'

import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import classes from './Login.module.css'
import { useAuth } from '../../contexts/Auth'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginRight: '2.5px',
            marginLeft: '2.5px'
        }
    }
}))

const VALIDATE_EMAIL = gql`
    query EmailCheck($email: String!) {
        emailCheck (email: $email) {
            email
            ssoProviders {
                uuid
                name
                logo
            }
            message
        }
    }
`

const LOGIN = gql`
    mutation user_auth($input: ObtainJSONWebTokenInput!) {
        tokenAuth(input: $input) {
            token
        }
    }
`

const Login = () => {
    const { SHOW_RESET_PASSWORD, SHOW_SSO, API_BASE_URL } = window['runtimeConfig']
    const SSO_LOGIN_URL = API_BASE_URL + "/sso/ssologin"

    const { authenticate } = useAuth()
    const muiClasses = useStyles()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [rememberEmail, setRememberEmail] = useState(false);
    
    const { t } = useTranslation()
    const [consolidatedErrorMessage, setConsolidatedErrorMessage] = useState(null)

    const [loading, setLoading] = useState(false)

    const [ssoProviderInfo, setSsoProviderInfo] = useState(null)

    const location = useLocation()
    const from = location.state ? location.state.from : '/'

    const client = useApolloClient()

    useEffect(() => {
        const savedEmail = localStorage.getItem('BPMSavedEmail');
        if (savedEmail) {
            setRememberEmail(true);
            setEmail(savedEmail);
        }
    }, []);

    const handleShowPassword = () => {
        setShowPassword(prevState => !prevState)
    }

    const handleRememberEmailChange = (e) => {
        setRememberEmail(e.target.checked);
    }

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (rememberEmail) {
            localStorage.setItem('BPMSavedEmail', email);
        } else {
            localStorage.removeItem('BPMSavedEmail');
        }

        setLoading(true)
        if (ssoProviderInfo) {
            client.mutate({
                mutation: LOGIN,
                variables: {
                    input: {
                        email: email,
                        password: password
                    }
                }
            })
            .then(res => {
                const authToken = res.data.tokenAuth.token
                authenticate(authToken, from)
            })
            .catch(err => {
                console.log(err)
                setConsolidatedErrorMessage(err.message)
            })
        } else {
            client.query({
                query: VALIDATE_EMAIL,
                variables: {email: email},
                fetchPolicy: 'network-only',
            })
            .then(res => {
                setSsoProviderInfo(res.data.emailCheck)
            })
            .catch(err => {
                console.log(err)
                setConsolidatedErrorMessage(err.message)
            })
        }
        setLoading(false)
    }

    const handleProviderSelect = async (provider) => {
        try {
            window.location.href = SSO_LOGIN_URL + '/' + provider.uuid + '/'
        } catch (error) {
            console.error("Error al iniciar la autenticación:", error);
        }
    };

    const getSsoAndPasswordComponents = () => {
        if (ssoProviderInfo && ssoProviderInfo.ssoProviders.length > 0) {
            return (
                <>
                    <Typography style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}>{t('common:orContinueWith')}:</Typography>
                    {
                        ssoProviderInfo.ssoProviders.map((provider, index) => {
                            return(
                                <CustomPrimaryButton
                                    key={'prodiver_' + index}
                                    className={classes.Button}
                                    onClick={() => {handleProviderSelect(provider)}}
                                    label= {provider.name}
                                    img={provider.logo ? <img src={provider.logo} alt={`${provider.name} logo`} style={{ width: '20px', marginRight: '10px' }} /> : null}
                                />
                            )
                        })
                    }
                </>
            )     
        } else {
            return null
        }
    }

    return (
        <div className={classes.Container}>
            <div className={classes.OptionsContainer}>
                <form className={[classes.LoginForm, muiClasses.root].join(' ')} onSubmit={handleSubmit}>
                    <div className={classes.Logo}>
                        <img src={'//d20xc7q49jirs4.cloudfront.net/static/img/login.png'} alt='bubble_logo' style={{ width: '280px', marginBottom: '20px !important' }} />
                    </div>
                    <TextField
                        label={t('common:loginUserName')}
                        className={classes.TextField}
                        value={email}
                        onChange={handleEmailChange}
                        required
                        InputLabelProps={{ required: false }}
                        error={Boolean(consolidatedErrorMessage)}
                        InputProps={{ readOnly: ssoProviderInfo ? true : false }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={rememberEmail}
                                onChange={handleRememberEmailChange}
                                style={{padding: '0px 0px 0px 5px', margin: 0, fontSize: 16, color: 'primary'}}
                            />
                        }
                        label={t("common:rememberMyEmail")}
                    />
                    {ssoProviderInfo ? 
                        <TextField
                            className={classes.TextField}
                            label={t('common:password')}
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                            InputLabelProps={{ required: false }}
                            autoFocus
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={handleShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            error={Boolean(consolidatedErrorMessage)}
                        />
                    : null}
                    <CustomPrimaryButton className={classes.Button} type='submit' label={ssoProviderInfo ? t('common:login') : t('common:next') } disabled={loading} />
                    {getSsoAndPasswordComponents()}
                    <br/>
                    <p style={{ width: '400px' }}>{t('common:loginhAdvertisement')}</p>
                </form>
                {SHOW_SSO ? <CustomPrimaryButton className={classes.Button} label={t('common:SSO')} onClick={() => { window.location.href = SSO_LOGIN_URL; }} /> : null}
            </div>

            {SHOW_RESET_PASSWORD ?
                <p className={classes.ForgotPassword}>{t('common:forgotYourPassword')} <Link to='/pr' className={classes.ResetLink}>{t('common:clickHereToReset')}</Link></p>
                :
                <p className={classes.ForgotPassword}>{`${t('common:havingLoginProblems')} info@bubblebpm.com`}</p>
            }
        </div>
    )
}

export default Login