import React from 'react'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { RemoveBPMApplicationMessages } from '../../../../redux/reducers/BPMReducer'
import { useApplicationSnackMessages } from '../../../../redux/selectors'
import { useDispatch } from 'react-redux'

export default function SnackMessage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const snackMessages = useApplicationSnackMessages();

    const onClickAlert = (message) => {
        if (message.clckRedirect) {
            history.push(message.clckRedirect)
        }
        dispatch(RemoveBPMApplicationMessages(message.id))
    }

    return (
        <div>
            {snackMessages.map((message, index) => (
                <Snackbar
                    key={index}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={true}
                    autoHideDuration={message.duration || 3000}
                    onClose={() => RemoveBPMApplicationMessages(message.id)}
                    style={ message.clckRedirect ? {cursor: 'pointer'} : message.snakBarCursor }
                >
                    <Alert onClick={() => onClickAlert(message)} variant='filled' severity={message.variant}>
                        {message.text}
                    </Alert>
                </Snackbar>
            ))}
        </div>
    )
}
