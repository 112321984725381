import React from 'react'

import { useTranslation } from 'react-i18next'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'

import classes from './Login.module.css'


const SsoFailed = (props) => {
    const { t } = useTranslation()

    const handleClick = (event) => {
        props.history.push("/")
    }

    return (
        <div className={classes.Container}>
            <div className={classes.OptionsContainer}>
                <div className={classes.Logo}>
                    <img src={'//d20xc7q49jirs4.cloudfront.net/static/img/login.png'} alt='bubble_logo' style={{ width: '280px' }} />
                </div>
                <p className={classes.LoginFailed}>Login failed</p>
                <CustomPrimaryButton className={classes.Button} label={t('common:goToLogin')} onClick={handleClick}/>
            </div>

            <p className={classes.ForgotPassword}>{`${t('common:havingLoginProblems')} info@bubblebpm.com`}</p>
        </div>
    )
}

export default SsoFailed;